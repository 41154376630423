/**
 * Update DOM elements with Ajax results
 *
 * @param {number} quantity - quantity
 * @param {string} selector - DOM element to look up in the tile's qty element
 * @return {undefined}
 */
 function updateTile(quantity, selector) {
    $(selector).empty().html(quantity);
    var unitQuantity = $('.unit').html();
    if (quantity && quantity > 0) {
        if(unitQuantity && unitQuantity.trim() == 'kg') {
            var roundedPrice = (Math.round((quantity)*10000)/10000).toString().replace('.', ',');
            $(selector).empty().html(roundedPrice);
        }
        $(selector).closest('.add-to-cart').find('.tile-add-to-cart').removeClass('show');
        $(selector).closest('.add-to-cart').find('.update-quantity').addClass('show');
    } else {
        $(selector).closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
        $(selector).closest('.add-to-cart').find('.update-quantity').removeClass('show');
    }
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function updateQuantities() {
    var items = $('.cart-json').data('cart');
    $('.add-to-cart').find('.tile-add-to-cart').addClass('show');
    $('.add-to-cart').find('.update-quantity').removeClass('show');
    if (items && items.itemsquantities && items.itemsquantities.length > 0) {
        items.itemsquantities.forEach((item) => {
            var variableWeight = $(`.itemquantity-${item.id} .quantity`).closest('.add-to-cart').data('variable-weight');
            if (variableWeight) {
                updateTile(Math.round((item.qty * item.unitValue)*10000)/10000, `.itemquantity-${item.id} .quantity`);
            } else {
                updateTile(item.qty, `.itemquantity-${item.id} .quantity`);
            }
        });
    }
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    return $($el).closest('.add-to-cart').data('pid');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-add-to-cart');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given change qty button
 * @return {string} - The provided URL to use when changing quantity
 */
function getUpdateQuantityUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-update-quantity');
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choice-of-bonus-product');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    let bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const htmlString = `${'<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="'}${data.maxBonusItems}"`
        + `data-UUID="${data.uuid}"`
        + `data-pliUUID="${data.pliUUID}"`
        + `data-addToCartUrl="${data.addToCartUrl}"`
        + 'data-pageStart="0"'
        + `data-pageSize="${data.pageSize}"`
        + `data-moreURL="${data.showProductsUrlRuleBased}"`
        + `data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">`
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + `    <span class="">${data.labels.selectprods}</span>`
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success(response) {
            const parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {jquery} el - DOM container for a given add to cart button
 */
function handlePostCartAdd(response, el) {
    $('.minicart').trigger('count:update', response);
    if (response && response.cart && response.cart.items) {
        const pid = el.closest('.add-to-cart').data('pid');
        const products = response.cart.items.filter((item) => item.id === pid);
        if (products && products.length > 0) {
            var product = products.pop();
            el.closest('.add-to-cart').find('.update-quantity').addClass('show');
            if (product.variableWeight) {
                el.closest('.add-to-cart').find('.update-quantity .quantity').html((Math.round(product.unitData.value *100)/100).toString().replace('.', ','));
            } else {
                el.closest('.add-to-cart').find('.update-quantity .quantity').html('1');
            }                
            el.removeClass('show');
        }
    }

    const messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(`<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${
            response.message
        }</div>`);

        setTimeout(() => {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the quantity change button
 * @param {jquery} el - DOM container for a given quantity change button
 */
function updateQuantity(response, el) {
    if (response && response.items && response.items) {
        let product;
        const pid = el.closest('.add-to-cart').data('pid');
        const products = response.items.filter((item) => item.id === pid);
        if (products && products.length > 0) {
            product = products.pop();
            if (product.variableWeight) {
                var unitQuantity = $('.unit').html();
                if(unitQuantity && unitQuantity.trim() == 'kg') {
                    var roundedPrice = (Math.round((product.quantity * product.unitData.value)*1000)/1000).toString().replace('.', ',');
                    el.closest('.add-to-cart').find('.update-quantity .quantity').html(roundedPrice);      
                } else {
                    el.closest('.add-to-cart').find('.update-quantity .quantity').html(roundedPrice);
                }
            } else {
                el.closest('.add-to-cart').find('.update-quantity .quantity').html((Math.round(product.quantity*100)/100).toString().replace('.', ','));
            }
        }
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // reporting urls hit on the server
            },
            error() {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Makes a CSRF-token research in the twolineheader element to fill the product-notes-form
 * Deletes the previous csrf_token if already exists
 * @param {Element} div - principal div in notes form
 */
function fillCSRFToken(modalForm) {
    var csrfTokenName = $('#customerConnectTk input[type="hidden"]').attr('name');
    var csrfTokenValue = $('#customerConnectTk input[type="hidden"]').val();

    if (csrfTokenName && csrfTokenValue) {
        // Search and deletes previous input (to ignore possible cached elements)
        modalForm.find('input[name="' + csrfTokenName + '"]').remove();
        // Creates new input element
        var csrfInput = $('<input>', {
            type: 'hidden',
            name: csrfTokenName,
            value: csrfTokenValue
        });
        // Append new input element
        modalForm.find('.modal-body').append(csrfInput);
    }
}

module.exports = {
    addToCart() {
        $(document).on('click', 'button.tile-add-to-cart', function () {
            const el = $(this);

            $('body').trigger('product:beforeAddToCart', this);

            const pid = getPidValue($(this));

            let $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            const addToCartUrl = getAddToCartUrl(el);

            const form = {
                pid,
                quantity: 0
            };

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success(data) {
                        handlePostCartAdd(data, el);
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error() {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    updateQuantityCart() {
        $(document).on('click', 'button.tile-update-quantity', function () {
            const el = $(this);

            $('body').trigger('product:beforeAddToCart', this);

            const pid = getPidValue($(this));
            const updateQuantityUrl = getUpdateQuantityUrl($(this));
            const decrease = $(this).data('decrease');

            let $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            const form = {
                pid,
                decrease,
                quantity: 1
            };

            $(this).trigger('updateAddToCartFormData', form);
            if (updateQuantityUrl) {
                $.ajax({
                    url: updateQuantityUrl,
                    method: 'GET',
                    data: form,
                    success(data) {
                        $('.minicart').trigger('count:update', data);
                        $('body').trigger('product:afterAddToCart', data);
                        $('.minicart .minicart-quantity .text').text(data.quantityTotal);
                        var prodsQuantity = 0;
                        if (data && data.basket && data.basket.items) {
                            const id = el.closest('.add-to-cart').data('pid');
                            const products = data.basket.items.filter((item) => item.id === id);
                            if (products.length === 0) {
                                // Veficar que esta en detailsPDP para ver si va con el sticky, para modificar los dos
                                const isInDetailsPDP = el.closest('.detailsPDP').length > 0;

                                if (isInDetailsPDP) {
                                    // Modificamos los dos elementos .add-to-cart dentro de .detailsPDP que serían el propio de la PDP y el sticky
                                    $('.detailsPDP .add-to-cart').each(function () {
                                        const buttonContainer = $(this);
                            
                                        buttonContainer.find('.update-quantity').removeClass('show');
                                        buttonContainer.find('.tile-add-to-cart').addClass('show');
                                    });
                                } else {
                                    // Modifica el boton .add-to-cart donde se hizo clic
                                    el.closest('.add-to-cart').find('.update-quantity').removeClass('show');
                                    el.closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
                                }
                            }

                            const items = data.basket.items;

                            for (var i = 0; i < items.length; i++) {
                                if (items[i].unitData && items[i].unitData.unit === 'kg') {
                                    prodsQuantity++;
                                } else {
                                    prodsQuantity += items[i].quantity;
                                }
                            }
                            $('.minicart .minicart-quantity .text').text(prodsQuantity.toString().replace('.', ','));
                        } else if (data && data.items) {

                            const addToCartButtons = $('.add-to-cart');

                            // Modificamos los dos elementos .add-to-cart (normal y sticky) presentes en la PDP
                            addToCartButtons.each(function () {
                                const buttonContainer = $(this);
                
                                updateQuantity(data, buttonContainer);
                            });


                            const items = data.items;

                            for (var i = 0; i < items.length; i++) {
                                if (items[i].unitData && items[i].unitData.unit === 'kg') {
                                    prodsQuantity++;
                                } else {
                                    prodsQuantity += items[i].quantity;
                                }
                            }
                            $('.minicart .minicart-quantity .text').text(prodsQuantity.toString().replace('.', ','));
                        }
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error() {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    updateMinicart() {
        $('body').on('cart:update', () => {
            updateQuantities();
        });
    },
    notesTokenModalEvent() {
        $('.modal.center-modal-product-notes').on('shown.bs.modal', function () {
            fillCSRFToken($(this));
        })
    },
    updateQuantities: updateQuantities,
};
